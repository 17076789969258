const dev = {
  STRIPE_KEY: "pk_test_DYK2Ji0k4GLXQknkBqke0AeN",
  s3: {
    REGION: "us-east-2",
    BUCKET: "notes-app-2-api-dev-attachmentsbucket-l2vwtha0r7qi"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api.permutize-scratch.com/dev"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_cyuVHH3YV",
    APP_CLIENT_ID: "49ooasn1d8g4s871bk37le6h93",
    IDENTITY_POOL_ID: "us-east-2:c2340007-9478-4b4a-b347-70a7a8d5b283"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_DYK2Ji0k4GLXQknkBqke0AeN",
  s3: {
    REGION: "us-east-2",
    BUCKET: "notes-app-2-api-prod-attachmentsbucket-1dg3ht57m1kcy"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api.permutize-scratch.com/prod"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_3ljujJ7by",
    APP_CLIENT_ID: "fi1i471lusm150j0gebs8ifk9",
    IDENTITY_POOL_ID: "us-east-2:d9cd3e5c-4990-4bd8-b59a-7847ab3b9575"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};